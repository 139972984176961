@import '../../styles/_variables.scss';

:local {
  .headerCardHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .headerItem {
    display: block;
    padding: 10px;
  }

  .bad {
    color: $primary
  }

  .good {
    color: $secondary
  }

  .centerIcon {
    text-align: center;
  }

  .activityHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .activityList {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  @media (max-width: 600px) {
    .activityHolder {
      display: block;
    }
  }

  .toggleSlider{
    text-align: right;
  }

  .userProfilePadding {
    padding-top: 35px;
  }

  .sortBy {
    min-width: 120px !important;
    padding-left: 10px;
    padding-right: 20px;
    margin-right: 10px;
  }

  .infoCard {
    height: 100%;
    background-color: $grey !important;
  }

  .mobileTitle {
    display: none;
    @media (max-width: 600px) {
      display: inherit;
    }
  }

  .baseCard {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 100%;
    background-color: $grey !important;
    transition: background-color 0.3s;
}

.baseCard:hover {
    background-color: white !important;
}
    
}