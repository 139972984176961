@import '../../styles/_variables.scss';

:local {
    .baseCard {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        height: 100%;
        background-color: $grey !important;
        transition: background-color 0.3s;
    }

    .baseCard:hover {
        background-color: white !important;
    }

    .progressBar {
        margin-top: auto;
    }
}