:local {
    .debtCard {
        max-width: 500px;
    }

    .formHolder {
        display: flex;
        flex-direction: column;
    }


    .avatarHolder {
        display: flex;
    }

    .arrow {
        padding: 5px;
    }
}