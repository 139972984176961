$body-font: 'Roboto', -apple-system, sans-serif;

$title-font: 'Glegoo', -apple-system, sans-serif;

// Breakpoints
$screen-xs: 0px;
$screen-sm: 600px;
$screen-md: 960px;
$screen-lg: 1280px;

/* https://material.io/tools/color/#!/?view.left=0&view.right=0&primary.color=FF5722 */

/* RGB */
$primary: #ff5722;
$primary-dark: #c41c00;
$primary-light: #ff8a50;
$secondary-dark: rgba(60, 110, 113, 1);
$secondary: rgba(112, 174, 110, 1);
$secondary-light: rgba(190, 238, 98, 1);

$grey: #eceff1;
$dark-grey: #29434e;