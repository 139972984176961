@import '../../styles/_variables.scss';

:local {
    .userCard {
        max-width: 300px;
        min-width: 300px;
        height: fit-content;
    }

    .userCard img {
        height: 300px;
    }

    .cardHeader {
        font-family: $title-font;
    }

    .cardImg {
        object-fit: cover;
    }

    .userLink:hover {
        text-decoration: underline;
    }

    .userCardSticky {
        position: -webkit-sticky;
        position: sticky;
        top: 80px;
    }
}