@import '../../styles/_variables.scss';
:local {
  h1, h2, h3, h4 {
    font-family: $title-font
  }

  .card {
    margin: 10px;
  }

  .cardHeader {
    display: flex;
    align-items: center;
  }
  .debtText {
    padding: 0 0.2em 0 0.2em;
    color: $primary
  }
  .creditText {
    padding: 0 0.2em 0 0.2em;
    color: $secondary
  }

  .subText {
    color: grey;
    font-size: 0.6em;
  }

  .cardPaid {
    background-color: $grey !important;
    color: $dark-grey !important;
    h1 {text-decoration: line-through;}
  }
  @keyframes fadeout {
    from {opacity: 1}
    to {opacity: 0}
  }
}