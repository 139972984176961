@import '../../styles/variables';

:local {
    .title {
        font-size: 3rem;
        text-align: center;
    }

    .loginLink {
        font-size: 2rem;
        color: $secondary;
    }


    .loginButtonContainer {
        text-align: center;
    }

}