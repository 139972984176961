@import '~normalize.css';
@import url('https://fonts.googleapis.com/css?family=Roboto|Glegoo');
  body{
    font-family: 'Roboto', -apple-system, sans-serif;
  }

  a {
    font-style: normal;
    color: black;
    text-decoration: none;
  }