@import '../../styles/_variables.scss';
:local {
    .searchIcon {
        position: absolute;
        display: flex;
        padding-left: 0.25rem;
    }

    .search {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: auto;
        margin-left: 2rem;
        background-color: $primary-light;
        border-radius: 6px;
        justify-self: center;
        transition-property: border-radius, height;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
        @media screen and (max-width: 992px) {
            width: 2rem;
            overflow: hidden;
        }

        &:hover {
            background-color: adjust-color($color: $primary-light, $lightness: 5%)
        }
        &:focus-within {
            border-radius: 0px;
            align-self: stretch;

            @media screen and (max-width: 992px) {
                overflow: visible;
                position: fixed;
                width: 100%;
                top: 0px;
                left: 0px;
                margin-left: 0px;
                height: 56px;
                z-index: 1;
            }
        }
    }
    .search input {
        padding-top: 0.5rem;
        padding-right: 1rem;
        padding-left: 3rem;
        width: 10rem;

        transition-property: width;
        transition-duration: 0.1s;
        transition-timing-function: ease-in-out;

        &:focus {
            width: 20rem;
        }

    }

    .resultsPaper {
        width: 100%;
        color: black;
        margin-top: 15px;
        overflow: visible;
    }

    .resultsHolder {
        padding: 5px;
    }

}