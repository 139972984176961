:local {
  .inputBox {
    width: 100%
  }

  .loginFailText {
    font-style: italic;
    color: red;
  }
  .formHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card {
    align-self: center;
    max-width: 600px;
    display: inline-block;
    min-width: 500px;
  }

  @media screen and (max-width: 992px) {
    .card {
      padding-top: 10px;
      max-width: 100%;
      min-width: 90%;
    }
  }
}