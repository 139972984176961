@import '../../styles/variables';

:local {
  .layout {
    display: flex;
    flex-direction: row;
  }

  .userCard {
    height: fit-content;
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
  }

  .activty {
    width: 100%;
    padding-left: 10px;
  }

  .backgroundCard {
    position: absolute;
    width: 100%;
    height: 400px;
    margin-left: -80px;
    margin-top: -40px;
    z-index: -100;
    background: $grey !important;
  }
  @media (max-width: 992px) {
    .backgroundCard{
      margin-left: 0px;
    }    
  }
  @media screen and (max-width: 600px) {
    .userCard {
      display: none;
    }
    .backgroundCard {
      margin-left: -5px
    }
    }
    
}
